/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import _debounce from 'lodash/debounce';

import { PostData, defaultFailedResponse } from '@components/auth/fetchData';
import Dropdown from './Dropdown';

import './SearchBar.scss';

class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: [],
            product_title: '',
            car_condition:
                this.props.car_condition !== undefined
                    ? this.props.car_condition
                    : 'used',
            feature:
                this.props.feature !== undefined ? this.props.feature : 'used',
            isCondition:
                this.props.isCondition !== undefined
                    ? this.props.isCondition
                    : 'No'
        };
        this.handleOnChange = _debounce(this.handleOnChange, 300).bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });

        const { value } = event.target;
        let apiData;
        let getFeature =
            this.state.feature == 'used' ? '' : this.state.feature;
        let getCondition = this.state.isCondition;
        let getCond =
            this.state.isCondition == 'Yes' ? this.state.car_condition : '';
        let getSuggest = `${getFeature}auto-suggest${getCond}`;
        if (getFeature == 'exchange' && getCondition == 'Yes') {
            let selected_category =
                document.getElementById('exchangeForm').exchangecategory.value;
            apiData = `category_id=${selected_category}&type=${this.state.car_condition}`;
        } else {
            apiData = `type=${this.state.car_condition}`;
        }
        if (value.length <= 2) {
            document.getElementById(getSuggest).style.display = 'none';
            return;
        }

        fetch(`${this.props.ENV_WEB_URL}/getProductSearch?${apiData}&search_term=${event.target.value}`)
            .then(response =>
                response
                    .json()
                    .catch(defaultFailedResponse)
            )
            .then(data => {
                if (!data?.data?.length) {
                    return
                }
                this.setState({
                    suggestions: data.data.filter(pro_title =>
                        pro_title.product_title
                            .toLowerCase()
                            .includes(event.target.value.toLowerCase())
                    )
                });

                document.getElementById(getSuggest).style.display = 'block';
                $(document).on('mouseup', e => {
                    const container = $(document.getElementById(getSuggest));
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                        document.getElementById(getSuggest).style.display = 'none';
                    }
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    getAutoSuggest({ url, data = {} }) {
        PostData(url, data).then(result => {
            const responseJson = result;
            if (responseJson.code == 'success') {
                this.setState({
                    suggestions: responseJson.data
                });
                const getFeature =
                    this.state.feature == 'used' ? '' : this.state.feature;
                const getCondition =
                    this.state.isCondition == 'Yes'
                        ? this.state.car_condition
                        : '';
                const getSuggest = `${getFeature}auto-suggest${getCondition}`;
                document.getElementById(getSuggest).style.display = 'block';
            } else if (responseJson.error_code == 403) {
                this.setState({
                    errors: responseJson.errors
                });
            } else {
                console.log('Error');
            }
        });
    }

    handleOnClick(event) {
        const getFeature =
            this.state.feature == 'used' ? '' : this.state.feature;
        const getCondition =
            this.state.isCondition == 'Yes' ? this.state.car_condition : '';
        const getMake = `${getFeature}make${getCondition}`;
        const getModel = `${getFeature}model${getCondition}`;
        const getTrim = `${getFeature}trim${getCondition}`;
        const getYear = `${getFeature}year${getCondition}`;
        const getCat = `${getFeature}category${getCondition}`;
        const getTitle = `${getFeature}product_title${getCondition}`;
        const getSuggest = `${getFeature}auto-suggest${getCondition}`;

        document.getElementById(getMake).value =
            event.target.getAttribute('data-make');
        document.getElementById(getModel).value =
            event.target.getAttribute('data-model');
        document.getElementById(getTrim).value =
            event.target.getAttribute('data-trim');
        if (this.state.car_condition != 'new') {
            document.getElementById(getYear).value =
                event.target.getAttribute('data-year');
        }
        document.getElementById(getCat).value =
            event.target.getAttribute('data-category');
        document.getElementById(getTitle).value =
            event.target.getAttribute('data-title');
        document.getElementById(getSuggest).style.display = 'none';
    }

    render() {
        return (
            <div className="form-group search-bar" id="auto-search-obv">
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        id={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }product_title${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                        placeholder="Search Vehicle"
                        onChange={this.handleOnChange}
                    />
                </div>
                <span>
                    <input
                        type="hidden"
                        id={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }make${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                        name={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }make${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                    />
                    <input
                        type="hidden"
                        id={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }model${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                        name={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }model${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                    />
                    <input
                        type="hidden"
                        id={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }trim${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                        name={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }trim${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                    />
                    {this.state.car_condition == 'used' ? (
                        <input
                            type="hidden"
                            id={`${
                                this.state.feature == 'used'
                                    ? ''
                                    : this.state.feature
                            }year${
                                this.state.isCondition == 'Yes'
                                    ? this.state.car_condition
                                    : ''
                            }`}
                            name={`${
                                this.state.feature == 'used'
                                    ? ''
                                    : this.state.feature
                            }year${
                                this.state.isCondition == 'Yes'
                                    ? this.state.car_condition
                                    : ''
                            }`}
                        />
                    ) : (
                        ''
                    )}
                    <input
                        type="hidden"
                        id={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }category${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                        name={`${
                            this.state.feature == 'used'
                                ? ''
                                : this.state.feature
                        }category${
                            this.state.isCondition == 'Yes'
                                ? this.state.car_condition
                                : ''
                        }`}
                    />
                    <Dropdown
                        suggestions={this.state.suggestions}
                        onClick={this.handleOnClick}
                        feature={this.state.feature}
                        isCondition={this.state.isCondition}
                        car_condition={this.state.car_condition}
                    />{' '}
                </span>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        ENV_WEB_URL: state.initState.WEB_URL ? state.initState.WEB_URL : '',
        ...ownProps
    };
}
export default connect(mapStateToProps)(SearchBar);
