import React from 'react';
import PropTypes from 'prop-types';
import { firstLetterUpperCase } from '@components/Utilities';

import './FormErrors.scss';

export const FormErrors = ({ formErrors }) => {
    if (typeof formErrors === 'string') {
        return (
            <div className="error">
                <p className="text-danger" key={1}>
                    {formErrors}
                </p>
            </div>
        );
    }
    return (
        <div className={`error ${Object.keys(formErrors).length > 0 ? 'show' : ''}`}>
            {Object.keys(formErrors).map(fieldName => {
                const keyName = `${fieldName}-error-span`;
                const showFieldName = fieldName.replace(/_/g, ' ').replace('[]', '');
                if (formErrors[fieldName].length > 0) {
                    if (typeof formErrors[fieldName] === 'string' && !/^\s/.test(formErrors[fieldName])) {
                        return <small key={keyName}>{`${formErrors[fieldName]} ${showFieldName.toLowerCase()}`}</small>;
                    }
                    if (formErrors[fieldName][0].toLowerCase().indexOf(fieldName.replace(/_/g, ' ')) > -1) {
                        return <small key={keyName}>{formErrors[fieldName][0]}</small>;
                    }
                    return (
                        <small key={keyName}>
                            {firstLetterUpperCase(showFieldName)} {formErrors[fieldName]}
                        </small>
                    );
                }
                return '';
            })}
        </div>
    );
};

FormErrors.propTypes = {
    formErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

const ValidateExtension = (value, allowedFiles) => {
    const regex = new RegExp(`([a-zA-Z0-9s_\\.-:])+(${allowedFiles.join('|')})$`);
    if (!regex.test(value.toLowerCase())) {
        return false;
    }
    return true;
};

const ValidateFields = validation => {
    let isValid = false;
    let dataToCheck;
    const fieldValidationErrors = {};
    const inputsToCheck = Object.keys(validation);
    inputsToCheck.every(value => {
        dataToCheck = validation[value];
        Object.keys(dataToCheck).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(dataToCheck, key)) {
                if (key !== 'value') {
                    const valueToCheck = dataToCheck.value;
                    if (valueToCheck || Object.prototype.hasOwnProperty.call(dataToCheck, 'zero')) {
                        if (!fieldValidationErrors?.[value]) {
                            switch (key) {
                                case 'email':
                                    isValid = valueToCheck.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                                    fieldValidationErrors[value] = isValid ? '' : ' is invalid';
                                    break;
                                case 'website':
                                    isValid = valueToCheck.match(
                                        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
                                    );
                                    fieldValidationErrors[value] = isValid ? '' : ' is invalid';
                                    break;
                                case 'password':
                                    isValid = String(valueToCheck).length >= 6;
                                    fieldValidationErrors[value] = isValid ? '' : ' is too short';
                                    break;
                                case 'number':
                                    if (Number.isNaN(valueToCheck)) {
                                        isValid = false;
                                    } else isValid = true;
                                    fieldValidationErrors[value] = isValid ? '' : 'Please enter valid ';
                                    break;
                                case 'required':
                                    isValid = String(valueToCheck).length >= 1;
                                    fieldValidationErrors[value] = isValid ? '' : ' is required';
                                    break;
                                case 'min_val':
                                    isValid = parseInt(valueToCheck, 10) >= parseInt(dataToCheck[key], 10);
                                    fieldValidationErrors[value] = isValid
                                        ? ''
                                        : ` should be higher than ${dataToCheck[key]}`;
                                    break;
                                case 'max_val':
                                    isValid = parseInt(valueToCheck, 10) <= parseInt(dataToCheck[key], 10);
                                    fieldValidationErrors[value] = isValid
                                        ? ''
                                        : ` should be lesser than ${dataToCheck[key]}`;
                                    break;

                                case 'length':
                                    isValid = String(valueToCheck).length === parseInt(dataToCheck[key], 10);
                                    fieldValidationErrors[value] = isValid
                                        ? ''
                                        : ` length should be equal to ${dataToCheck[key]}`;
                                    break;

                                case 'multi_select': {
                                    const elements = document.getElementsByName(value);
                                    elements.forEach(eleKey => {
                                        if (elements[eleKey]?.value || elements[eleKey]?.selectedIndex) {
                                            isValid = false;
                                            fieldValidationErrors[value] = isValid ? '' : 'Please_Select';
                                        }
                                    });
                                    break;
                                }
                                case 'allowedFiles':
                                    isValid = ValidateExtension(valueToCheck, dataToCheck[key]);

                                    fieldValidationErrors[value] = isValid
                                        ? ''
                                        : `Please upload files having extension's: ${dataToCheck[key].join(
                                              ', '
                                          )} only.`;
                                    break;
                                default:
                                    break;
                            }
                        }
                    } else if (dataToCheck.input_type === 'select') {
                        fieldValidationErrors[value] = 'Please Select';
                    } else if (dataToCheck.input_type === 'file') {
                        fieldValidationErrors[value] = 'Please upload ';
                    } else {
                        fieldValidationErrors[value] = 'Please enter ';
                    }
                }
            }
        });

        if (Object.keys(fieldValidationErrors).length > 0) {
            return fieldValidationErrors[value] === '';
        }
        return true;
    });

    let haveErrors = 0;
    const validationErrors = Object.entries(fieldValidationErrors).reduce((obj, [key, value]) => {
        const newObject = {
            ...obj
        };
        if (value) {
            haveErrors = 1;
            newObject[key] = value;
            if (validation?.[key]?.error) {
                newObject[key] = validation[key].error;
            }
        }
        return newObject;
    }, {});

    if (haveErrors) {
        return {
            isValid: false,
            errors: validationErrors
        };
    }
    return {
        isValid: true
    };
};

export { ValidateFields as validateFields };
