import React, { Component } from 'react';

class TextBox extends Component {
    /*
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  class_name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired

*/
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            value: this.props.value !== undefined ? this.props.value : ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        let sendObj;
        this.setState({ value: event.target.value });
        if (this.props.onChange !== undefined) this.props.onChange(event);
        /* To update validation in formValidation object coming from parent component */
        event.preventDefault();
        sendObj = {
            name: event.target.name,
            value: event.target.value
        };
        if (this.props.updateValidation) this.props.updateValidation(sendObj);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
            // Check if there's any change in props, you can also use some unique, like the ID
            this.setState({
                name: nextProps.name,
                value: nextProps.value !== undefined ? nextProps.value : ''
            });
        }
    }

    render() {
        if (this.props.disabled) {
            return (
                <input
                    id={this.props.id}
                    name={this.props.name}
                    className={this.props.className}
                    placeholder={this.props.placeHolder}
                    type={this.props.type}
                    onChange={this.handleChange}
                    value={this.state.value}
                    disabled="disabled"
                />
            );
        }
        if (this.props.readOnly) {
            return (
                <input
                    readOnly
                    id={this.props.id}
                    name={this.props.name}
                    className={this.props.className}
                    type={this.props.type}
                    value={this.state.value}
                />
            );
        }
        return (
            <input
                autoComplete={
                    this.props.autocomplete ? this.props.autocomplete : 'off'
                }
                id={this.props.id}
                name={this.props.name}
                className={this.props.className}
                placeholder={this.props.placeHolder}
                type={this.props.type}
                onChange={this.handleChange}
                value={this.state.value}
            />
        );
    }
}

export default TextBox;
