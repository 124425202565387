/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import TextBox from '@components/Forms/partials/TextBox';
import SearchBar from '@components/Forms/partials/SearchBar';

const VehicleSearch = ({
    feature,
    isCondition,
    car_condition: carCondition,
    iskmsdriven,
    clickSearchVehicle
}) => {
    const formAtrr = feature === 'used' ? '' : feature;
    const formCondition = isCondition === 'Yes' ? carCondition : '';

    return (
        <div className="vehicle-search">
            <SearchBar
                feature={feature}
                carCondition={carCondition}
                isCondition={isCondition}
            />

            {iskmsdriven === 'Yes' ? (
                <div className="form-group">
                    <TextBox
                        className="form-control"
                        name={`${formAtrr}kms_driven${formCondition}`}
                        id="kms_driven"
                        type="number"
                        placeHolder="Enter KM Driven"
                    />
                </div>
            ) : (
                ''
            )}
            {formAtrr === 'exchange' ? (
                <a
                    href="#"
                    role="button"
                    className="link"
                    onClick={clickSearchVehicle}
                >
                    Or Enter Manually
                </a>
            ) : (
                ''
            )}
        </div>
    );
};

VehicleSearch.propTypes = {
    clickSearchVehicle: PropTypes.func.isRequired,
    iskmsdriven: PropTypes.string.isRequired,
    feature: PropTypes.string.isRequired,
    car_condition: PropTypes.string.isRequired,
    isCondition: PropTypes.string.isRequired
};

export default VehicleSearch;
