import React, {Component} from 'react'

class ErrorModal2 extends Component {

  constructor(props){
    super(props);
    this.state={
      errors:(this.props.errors !== undefined)?this.props.errors:""
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if( newProps.errors != this.props.errors ) {
      this.setState({
        errors:newProps.errors
      });
    }
  }

	removeModal(){
		document.getElementById('errors').style.display="none";
	}

	render(){
		var divStyle = {
		  display: 'block',
		  paddingRight: '15px',
			background: [
				'linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7))'
			]
		};
	  if (this.state.errors) {
	    return (
				<div id="errors" className="modal show" tabIndex="-1" role="dialog" style={divStyle}>
							<div className="modal-dialog" role="document">
									<div className="modal-content">
											<div className="modal-header">
													<h5 className="modal-title" id="myModalLabel"><i className="fa fa-info-circle text-info" aria-hidden="true"></i> Info</h5>
											</div>
											<div className="modal-body _14p thin">
													<p id="message">
															{atob(this.state.errors)}
													</p>
											</div>
											<div className="modal-footer">
													<button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.removeModal}>Close</button>
											</div>
									</div>
							</div>
					</div>
			);
	  }
		return (<div></div>);
	}
}
export default ErrorModal2;
