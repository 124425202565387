import React from 'react';
import './Dropdown.scss';

class Dropdown   extends React.Component{

    constructor(props) {
      super(props);
      this.state = {
        IsDrop:true
    }

    //this.handleEvent = this.handleEvent.bind(this);
    }
  
    render()
    {
        const option = this.props.suggestions.map(item =>(
            <li key={item.id} data-make ={item.make} data-model ={item.model} data-trim ={item.trim} data-category ={item.category_id} data-year ={item.year} onClick={this.props.onClick} data-title={item.product_title_show}>{item.product_title_show}</li>
        ))
        return <ul className="auto-suggest-li" id={`${this.props.feature=='used'?'':this.props.feature}auto-suggest${this.props.isCondition=='Yes'?this.props.car_condition:''}`}>{option}</ul>
    }
  
}

export default Dropdown